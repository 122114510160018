import React, {createContext, useState} from 'react';

export type UserContextType = {
    user:any, 
    setUser:any
};

export const userContext = createContext<UserContextType | null>(null);


export default function ContextProvider({ children }:any){
    const [user, setUser] = useState<any>({tabValue:"3"});
    return (
        <userContext.Provider value={{user, setUser}}>
            {children}
        </userContext.Provider>
    )
}