import { theme } from "../../muiTheme";

export {theme};

export const styles = {
    toolbarMargin: {
      ...theme.mixins.toolbar,
      marginBottom: '3em',
      [theme.breakpoints.down('md')]: {
        marginBottom: '2em',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1.25em',
      },
    },
    logo: {
      height: '8em',
      [theme.breakpoints.down('md')]: {
        height: '7em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '5.5em',
      },
    },
    logoContainer: {
      paddingLeft:"20px !important",
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    tabs: {
      marginLeft: 'auto',
      '& .MuiButtonBase-root.MuiTab-root': {
        fontSize: 20,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#808080',
      },
      '& .Mui-selected': {
        color: '#808080 !important',
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    tab: {
      ...theme.typography,
      minWidth: 10,
      marginLeft: '25px',
      color: 'white',
    },
  
    tabO: {
      ...theme.typography,
      minWidth: 10,
      marginLeft: '25px',
      color: 'red',
      fontWeight:"900"
    },
  
    hamburgerMenuIcon: {
      height: '40px',
      width: '40px',
    },
    menuIconContainer: {
      marginLeft: 'auto',
      color: 'white',
      '&:hover': {
        opacity: 1,
      },
    },
    appbar: {
      zIndex: theme.zIndex.modal + 1,
      backgroundColor:"#313131",
      // height:"50px !important"
      "& .MuiToolbar-root":{minHeight:"55px !important"}
    },
  };