import React from 'react';
import ContextProvider from "./src/context";
import Header from "./src/components/Header";
import { Container } from '@mui/material';
import Footer from "./src/components/Footer";

export const wrapRootElement = ({ element }) => {
  return (
    <ContextProvider>
      <Header/>
        <Container sx={{paddingTop:"55px", width:"100%", paddingLeft:"0px !important",paddingRight:"0px !important", maxWidth:"none !important"}}>
          {element}
        </Container>
      <Footer/>
    </ContextProvider>
  );
};