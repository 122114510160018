import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button, { ButtonPropsColorOverrides } from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//import logo from 'https://img.icons8.com/bubbles/344/amazon-alexa-logo.png';
import { StaticImage } from "gatsby-plugin-image"
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUser } from '../../hooks/useUser';
import { styles, theme } from "./style";
import { Typography } from '@mui/material';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    myAwesomeColor: true;
  }
}



const DesktopNavigation = () => {
  const { user, setUser } = useUser();


  const handleChange = (event: any, newValue: any) => {
    setUser({ ...user, tabValue: newValue });
  };


  return (
    // <TabContext value={user.tabValue}>
    <Tabs value={user.tabValue} onChange={handleChange} aria-label="nav tabs example" sx={styles.tabs} >
      <Tab sx={styles.tab} label="HOME" component={Link} to="/" value="/" />
      {/* <Tab sx={styles.tab} label="ABOUT US" component={Link} to="/about" value="/about/" /> */}
      <Tab sx={styles.tab} label="CONTACT" component={Link} to="/contact" value="/contact/" />
      <OutboundLink href="/rms-1" style={{ textDecoration: "none", margin: "auto 20px", }}>
        <Button variant="outlined" color="error" sx={{ color: "red", fontWeight: "900", fontSize: "20px", height: "35px" }}>LEARN MORE</Button>
      </OutboundLink>
    </Tabs>
    // </TabContext>
  );
};

const MobileNavigation = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <React.Fragment>
      <SwipeableDrawer sx={{ "& .MuiDrawer-paper": { backgroundColor: "#313131" } }} disableBackdropTransition={!iOS} disableDiscovery={iOS} open={openDrawer} onClose={() => setOpenDrawer(false)} onOpen={() => setOpenDrawer(true)} >
        <Box sx={styles.toolbarMargin} />
        <Paper sx={{ backgroundColor: "#313131" }}>
          <List disablePadding>

            <ListItem divider button component={Link} to="/" onClick={() => setOpenDrawer(false)} >
              {/* <ListItemText disableTypography>Home</ListItemText> */}
              <Typography color="#fff">HOME</Typography>
            </ListItem>

            {/* <ListItem divider button component={Link} to="/about" onClick={() => setOpenDrawer(false)} >
              <Typography color="#fff">ABOUT US</Typography>
            </ListItem> */}

            <ListItem divider button component={Link} to="/contact" onClick={() => setOpenDrawer(false)} >
              <Typography color="#fff">CONTACT</Typography>
            </ListItem>
            <ListItem divider button component={Link} to="/contact" onClick={() => setOpenDrawer(false)} sx={{padding:"5px 0 10px"}} >
              {/* <Typography color="red" sx={{ fontWeight: "900" }}>PRE-ORDER</Typography> */}
              <OutboundLink href="/rms-1" style={{ textDecoration: "none", margin: "auto 7px",  padding:"0 0 10px" }}>
        <Button variant="outlined" color="error" sx={{ color: "red", fontWeight: "900", fontSize: "20px", height: "35px"}}>LEARN MORE</Button>
      </OutboundLink>
            </ListItem>

          </List>
        </Paper>
      </SwipeableDrawer>
      <IconButton sx={styles.menuIconContainer} onClick={() => setOpenDrawer(!openDrawer)} disableRipple >
        {!openDrawer && <MenuIcon sx={styles.hamburgerMenuIcon} />}
        {openDrawer && <ClearIcon sx={styles.hamburgerMenuIcon} />}
      </IconButton>
    </React.Fragment>
  );
};

const Header = () => {
  const isMobileMode = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Fragment>
      <AppBar position="fixed" sx={styles.appbar} elevation={9} >
        <Toolbar disableGutters={true}>
          <Button disableRipple component={Link} to="/" sx={styles.logoContainer} >
            <StaticImage style={{ height: "50px", width: "200px" }} src="../../images/logoLong.png" alt="Logo" />
          </Button>
          {isMobileMode ? <MobileNavigation /> : <DesktopNavigation />}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

export default Header;
