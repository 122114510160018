// import { createTheme  } from '@mui/material/styles';
import { createTheme  } from '@mui/material';


export const theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      light: "#eb1010",
      main:"#eb1010",
      dark:"#eb1010",
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff7961',
      main: '#7f7f7f',
      dark: '#7f7f7f',
      contrastText: '#ffffff',
    },
  },
});



