// import { createTheme } from "@mui/material";
import {theme} from "../muiTheme";

// const theme = createTheme({
//     palette: {
//         mode: 'light',
//         primary: {
//           light: '#757ce8',
//           main: '#333333',
//           dark: '#002884',
//           contrastText: '#ffffff',
//         },
//         secondary: {
//           light: '#ff7961',
//           main: '#7f7f7f',
//           dark: '#7f7f7f',
//           contrastText: '#ffffff',
//         },
//       },

//   });

export default theme;