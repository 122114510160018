import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styles, theme } from "./style";
import { Box, Grid, Typography, Stack, IconButton } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Footer = () => {
    //const isMobileMode = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Box justifyContent="center" paddingBottom={4} paddingTop={2} sx={{ backgroundColor: "#313131", "& .MuiGrid-item":{paddingLeft:"0px !important"} }}>
            <Grid container spacing={2} sx={{ textAlign: "center", margin: "auto", justifyContent:"center" }} width="90%">
                <Grid item xs={12} md={3} sx={{[theme.breakpoints.down('md')]: { paddingBottom:"20px !important", borderBottom: "2px solid #9c9c9c" }}}>
                    <Stack spacing={1}>
                        <Typography color="#fff">PRODUCT</Typography>
                        <Link to="/rms-1" style={{textDecoration: "none"}}>
                        <Typography color="#a9a9a9" sx={{fontSize: "0.8rem", lineHeight: "1"}}>RMS-1</Typography>
                        </Link>
                    </Stack>
                </Grid>
                {/* <Grid item xs={12} md={3} sx={{[theme.breakpoints.down('md')]: { borderLeft: "0px", borderBottom: "2px solid #9c9c9c", paddingBottom:"20px !important" }, borderLeft: "2px solid #9c9c9c"}}>
                    <Stack spacing={1} >
                        <Typography color="#fff">ABOUT</Typography>
                        <Link to="/about" style={{textDecoration: "none"}}>
                        <Typography color="#a9a9a9" sx={{fontSize: "0.9rem", lineHeight: "1.2"}}>Team</Typography>
                        </Link>
                        <Link to="/terms" style={{textDecoration: "none"}}>
                        <Typography color="#a9a9a9" sx={{fontSize: "0.9rem", lineHeight: "1.2"}}>Terms & Conditions</Typography>
                        </Link>
                    </Stack>
                </Grid> */}
                <Grid item xs={12} md={3} sx={{[theme.breakpoints.down('md')]: { borderLeft: "0px", borderBottom: "2px solid #9c9c9c", paddingBottom:"20px !important" }, borderLeft: "2px solid #9c9c9c"}}>
                    <Stack spacing={1} >
                        <Typography color="#fff">SUPPORT</Typography>
                        <Link to="/contact" style={{textDecoration: "none"}}>
                        <Typography color="#a9a9a9" sx={{fontSize: "0.9rem", lineHeight: "1.2"}}>Contact us</Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}  sx={{[theme.breakpoints.down('md')]: { borderLeft: "0px", paddingBottom:"20px !important" }, borderLeft: "2px solid #9c9c9c"}}>
                    <Stack spacing={1} >
                        <Typography color="#fff">FOLLOW US</Typography>
                        <Stack direction="row" spacing={2} justifyContent="center" sx={{ margin: "auto" }}>
                            <OutboundLink href="https://www.facebook.com/rerum.ca" >
                                <IconButton aria-label="facebook" >
                                    <FacebookIcon sx={{ color: '#9c9c9c' }} />
                                </IconButton>
                            </OutboundLink>
                            <OutboundLink href="https://www.youtube.com/channel/UCiSoCwsUdRtkbXHeQ8p60aw" >
                                <IconButton aria-label="youtube">
                                    <YouTubeIcon sx={{ color: '#9c9c9c' }} />
                                </IconButton>
                            </OutboundLink>
                            {/* <OutboundLink href="" >
                                <IconButton aria-label="instagram">
                                    <InstagramIcon sx={{ color: '#9c9c9c' }} />
                                </IconButton>
                            </OutboundLink> */}
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;